import React from 'react';

import { useIntl } from 'react-intl-next';

import LinkExternalIcon from '@atlaskit/icon/utility/link-external';
import { Box, xcss } from '@atlaskit/primitives';

import { useAppContext } from '../../../../../common/ui/app-context';
import { FilterButton } from '../../../../../common/ui/quick-find/filter-button';
import { useSearchActions, useSelectedProduct } from '../../../../../controllers/store';
import {
	useQuickFindActions,
	useQuickFindQuery,
} from '../../../../../controllers/store/quick-find';
// eslint-disable-next-line @atlassian/tangerine/import/no-parent-imports
import { SpaceFilter } from '../../../filters/product-filters/space';

import { i18n } from './messages';

const inlineProductFilterStyles = xcss({
	display: 'flex',
	paddingInlineStart: 'space.100',
	gap: 'space.100',
	width: '100%',
	minWidth: '0', // Prevents the filter buttons from overflowing from its container and allow it to shrink as much as possible
});

export const InlineProductFilters = () => {
	const { formatMessage } = useIntl();
	const { generateSearchUrl, onNavigate, queryParams } = useAppContext();
	const [selectedProduct] = useSelectedProduct();
	const quickFindQuery = useQuickFindQuery();
	const { setQuickFindOpen } = useQuickFindActions();
	const { clearAllFilters } = useSearchActions();

	if (!selectedProduct) {
		return null;
	}

	const moreButtonOnClick = () => {
		setQuickFindOpen(false);

		// TODO: This is a temporary measure until we do QS-6716 post Team 25 where it syncs filters with FPS.
		// For now, executing a search query on FPS search will always clear the filters in QF as FPS filters
		// is the source of truth for filter state.
		clearAllFilters();

		const onNavigateCallback = typeof onNavigate === 'function' ? onNavigate : onNavigate.callback;
		onNavigateCallback(
			generateSearchUrl({
				...queryParams,
				text: quickFindQuery,
			}),
			'push',
		);
	};

	return (
		<Box xcss={inlineProductFilterStyles}>
			{/* TODO: Dynamically populate based on selected product. Hardcoded for Team25 */}
			<SpaceFilter />
			<FilterButton
				onClick={moreButtonOnClick}
				iconAfter={
					<LinkExternalIcon
						label={formatMessage(i18n.externalLinkIconLabel)}
						color="currentColor"
					/>
				}
			>
				{formatMessage(i18n.moreButtonLabel)}
			</FilterButton>
		</Box>
	);
};
