import { useCallback } from 'react';

import { type GasPayload } from '@atlaskit/analytics-gas-types';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { fg } from '@atlaskit/platform-feature-flags';

import { DEFAULT_GAS_CHANNEL } from '../../../common/constants/analytics';
import { sha256Hash } from '../../../common/utils/events/utils';
import {
	useAnalyticsAttributes,
	useApplicationMode,
	useExperimentConfig,
	useQueryUpdatedTime,
	useQueryVersion,
	useSearchSessionId,
} from '../../store';
import {
	useFetchEntitiesRequestId,
	useFetchSpacesRequestId,
	useQuickFindQuery,
} from '../../store/quick-find';

export const useQuickFindAnalytics = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireAnalyticsEvent = useCallback(
		(payload: GasPayload) => {
			createAnalyticsEvent({
				...payload,
				source: 'searchDialog',
			}).fire(DEFAULT_GAS_CHANNEL);
		},
		[createAnalyticsEvent],
	);

	return { fireAnalyticsEvent };
};

export const useQuickFindAttributes = () => {
	const [searchSessionId] = useSearchSessionId();
	const fetchEntitiesRequestId = useFetchEntitiesRequestId();
	const fetchSpacesRequestId = useFetchSpacesRequestId();
	const [queryUpdatedTime] = useQueryUpdatedTime();
	const quickFindQuery = useQuickFindQuery();
	const [queryVersion] = useQueryVersion();
	const [experimentConfig] = useExperimentConfig();
	const applicationMode = useApplicationMode();
	const [analyticsAttributes] = useAnalyticsAttributes();

	const { frontendExperiments = {}, backendExperiment } = experimentConfig;

	const commonAttributes = {
		...(!fg('platform_search-scaleable-statsig-layers') && { abTest: backendExperiment }),
		frontendExperiments: {
			activeExperiments: Object.keys(frontendExperiments).filter(
				(experiment) => frontendExperiments[experiment]?.serveExperiment,
			),
			...frontendExperiments,
		},
		...getCommonQueryAttributes(quickFindQuery),
		queryUpdatedAgo: queryUpdatedTime ? Date.now() - queryUpdatedTime : undefined,
		queryVersion,
		searchSessionId,
		fetchEntitiesTraceId: fetchEntitiesRequestId,
		fetchSpacesTraceId: fetchSpacesRequestId,
		isQuickFind: true,
		mode: applicationMode,
		...analyticsAttributes,
	};

	const nonPrivacySafeAttributes = {
		query: quickFindQuery,
	};

	return { commonAttributes, nonPrivacySafeAttributes };
};

export const getCommonQueryAttributes = (query: string) => {
	return {
		numberOfTerms: (query.match(/\w+/g) || []).length,
		queryHash: sha256Hash(query),
		queryHashSHA256: sha256Hash(query),
		queryLength: query.length,
		wordCount: (query.match(/\w+/g) || []).length,
	};
};
