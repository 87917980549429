import type { SearchExperimentLayer } from '@atlassian/search-experiment';

// Filter input types
export enum ConfluenceGraphQLDateFilterFieldType {
	LASTMODIFIED = 'LASTMODIFIED',
	CREATED = 'CREATED,',
}

export interface ConfluenceGraphQLFilterVariablesType {
	spacesFilter?: string[] | null;
	isVerified?: boolean | null;
	contributorsFilter?: string[] | null;
	range?: {
		field: ConfluenceGraphQLDateFilterFieldType;
		gt?: string | null;
		lt?: string | null;
	} | null;
	titleMatchOnly?: boolean;
	pageStatus?: string | null;
}

export interface ConfluenceGraphQLSearchVariablesMapper {
	query: string;
	filters: any[];
	contentEntities?: string[];
	cloudId: string;
	analyticsInfo?: { queryVersion: number; searchSessionId: string };
	pageBlogAttachmentLimit?: number;
	spaceLimit?: number;
	includeAncestors?: boolean;
	experimentId?: string;
	shadowExperimentId?: string;
	experimentLayers?: SearchExperimentLayer[];
}

export interface ConfluenceGraphQLSearchVariables {
	query: string;
	cloudIdARI: string;
	pageBlogAttachmentLimit: number;
	spaceLimit: number;
	contentEntities: string[];
	confluenceFilters: ConfluenceGraphQLFilterVariablesType;
	includeSpaces: boolean;
	includeAncestors: boolean;
	queryVersion?: number;
	searchSessionId?: string;
	experimentContext: {
		experimentId?: string;
		shadowExperimentId?: string;
		experimentLayers?: SearchExperimentLayer[];
	};
}

export interface ConfluenceGraphQLAncestor {
	id: string;
	title: string | null;
}

export interface ConfluenceGraphQLContentNode {
	id: string;
	title: string;
	type: 'page' | 'blogpost' | 'attachment';
	url: string;
	lastModified?: string | null;
	iconCssClass?: string | null;
	space?: {
		id: string;
		name?: string | null;
	} | null;
	confluenceEntity?: {
		ancestors: ConfluenceGraphQLAncestor[] | null;
	} | null;
}

export interface ConfluenceGraphQLSpaceNode {
	id: string;
	key?: string;
	title: string;
	type: 'space';
	url: string;
	iconPath?: string;
}

export interface ConfluenceGraphQLSearchQueryType {
	search: {
		pageBlogAttachments: {
			edges: {
				node?: ConfluenceGraphQLContentNode | null;
			}[];
			totalCount?: number | null;
		};
		spaces: {
			edges: {
				node?: ConfluenceGraphQLSpaceNode | null;
			}[];
		};
	};
}
