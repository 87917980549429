import React, { useMemo } from 'react';

import { useIntl } from 'react-intl-next';

import { IconTile } from '@atlaskit/icon';
import LinkExternalIcon from '@atlaskit/icon/utility/link-external';
import { ConfluenceIcon } from '@atlaskit/logo';
import type { TriggerProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

import {
	type ProductConfig,
	ProductKeys,
	useProductConfigs,
} from '../../../../../common/constants/products';
import { SEARCH_DIALOG_WIDTH_OBSERVER_ID } from '../../../../../common/constants/quick-find';
import { ProductKey } from '../../../../../common/constants/schemas/query-params';
import { useAppContext } from '../../../../../common/ui/app-context';
import { OAuthWarningWrapper } from '../../../../../common/ui/oauth-warning-wrapper';
import { FilterButton } from '../../../../../common/ui/quick-find/filter-button';
import { useElementWidth } from '../../../../../common/ui/width-observer';
import { isProductSmartlink } from '../../../../../common/utils/oauth-container';
import { ErrorBoundary } from '../../../../../controllers/error-boundary';
import { useIsUserOAuthed, useOAuthHandlers } from '../../../../../controllers/oauth';
import {
	useAvailableProducts,
	useBootstrap,
	usePrimaryProduct,
	useSelectedProduct,
} from '../../../../../controllers/store';
import {
	useQuickFindActions,
	useQuickFindQuery,
} from '../../../../../controllers/store/quick-find';

import { i18n } from './messages';

const inlineFilterStyles = xcss({
	display: 'flex',
	gap: 'space.100',
});

const inlineFilterSeparator = xcss({
	width: '1px',
	height: '29px', // As specified in the designs
	alignSelf: 'center',
	borderRight: `1px solid ${token('color.border')}`,
});

const AppButtonFor3PApps = ({
	productKey,
	redirectToFPSWithProductSelected,
	trigger,
}: {
	productKey: ProductKeys;
	productConfig: ProductConfig;
	redirectToFPSWithProductSelected: (productKey: ProductKeys) => void;
	trigger: (props: { triggerProps?: TriggerProps; userNeedsAuth: boolean }) => JSX.Element;
}) => {
	const [{ thirdPartyConfigs }] = useBootstrap();
	const isSmartlink = isProductSmartlink({ productKey, thirdPartyConfigs });
	const { userNeedsOAuth } = useIsUserOAuthed(productKey);
	const quickFindQuery = useQuickFindQuery();

	if (userNeedsOAuth) {
		return (
			<ErrorBoundary>
				<OAuthWarningWrapper
					key={productKey}
					product={productKey}
					postAuthAction={() => {
						redirectToFPSWithProductSelected(productKey);
					}}
					trigger={(triggerProps) => trigger({ ...triggerProps, userNeedsAuth: true })}
					isOnSearchDialog={true}
					useOAuthHandlers={useOAuthHandlers}
					useIsUserOAuthed={useIsUserOAuthed}
					isSmartlink={isSmartlink}
					searchQuery={quickFindQuery}
				/>
			</ErrorBoundary>
		);
	}

	return trigger({ userNeedsAuth: false });
};

// TODO: Render dynamic 1P products, hardcoded for now for Team 25
const supported3PApps = [ProductKey.GoogleDrive, ProductKey.Slack];

// Refer to the folowing designs for the Quick Find Dialog responsiveness
// https://www.figma.com/design/50bR8aLZ4eCuXlkrejuCiw/Q3-FY25-Enterprise-Search-Design?node-id=1576-34214&t=GOmwvwnkhe6CCgJs-0
export const InlineAppSelector = () => {
	const { formatMessage } = useIntl();
	const { onNavigate, generateSearchUrl, queryParams } = useAppContext();
	const { setQuickFindOpen } = useQuickFindActions();
	const quickFindQuery = useQuickFindQuery();
	const [selectedProduct, { selectProductFilter }] = useSelectedProduct();
	const availableProducts = useAvailableProducts();

	const productConfigs = useProductConfigs();
	const confluenceConfig = productConfigs[ProductKey.Confluence];
	const [primaryProduct] = usePrimaryProduct();

	const dialogWidth = useElementWidth(SEARCH_DIALOG_WIDTH_OBSERVER_ID);
	const isDialogAbove400px = dialogWidth > 400;
	const isDialogAbove450px = dialogWidth > 450;

	// TODO: QS-6617 Connect 3P products
	const apps3PToDisplay = useMemo(() => {
		return supported3PApps
			.filter((supported3PApp) => availableProducts.includes(supported3PApp))
			.map((productKey): [ProductKeys, ProductConfig] => [productKey, productConfigs[productKey]]);
	}, [availableProducts, productConfigs]);

	const redirectToFPSWithProductSelected = (productKey: ProductKeys) => {
		setQuickFindOpen(false);

		const onNavigateCallback = typeof onNavigate === 'function' ? onNavigate : onNavigate.callback;
		onNavigateCallback(
			generateSearchUrl({
				...queryParams,
				product: productKey,
				text: quickFindQuery,
			}),
			'push',
		);
	};

	const apps3pComponent = apps3PToDisplay.map(([productKey, productConfig]) => {
		const Icon = productConfig.Icon;
		return (
			<Tooltip
				content={formatMessage(i18n.appTooltipFor3PApps, {
					productName: productConfig.displayName,
				})}
			>
				<AppButtonFor3PApps
					key={productKey}
					productKey={productKey}
					productConfig={productConfig}
					redirectToFPSWithProductSelected={redirectToFPSWithProductSelected}
					trigger={({ triggerProps, userNeedsAuth }) => (
						<FilterButton
							{...triggerProps}
							contentFit={isDialogAbove450px ? 'default' : 'compact'}
							iconAfter={
								!userNeedsAuth && (
									<LinkExternalIcon
										label={formatMessage(i18n.externalLinkIconLabel)}
										color="currentColor"
									/>
								)
							}
							iconBefore={<Icon size="small" />}
							isSelected={false}
							onClick={() => {
								if (!userNeedsAuth) {
									redirectToFPSWithProductSelected(productKey);
								}
							}}
							spacing={!userNeedsAuth || isDialogAbove400px ? 'default' : 'compact'}
						>
							{isDialogAbove400px && productConfig.displayName}
						</FilterButton>
					)}
				/>
			</Tooltip>
		);
	});

	return (
		<Box xcss={inlineFilterStyles}>
			{primaryProduct === ProductKey.Atlas && !selectedProduct && apps3pComponent}

			<FilterButton
				contentFit={isDialogAbove450px ? 'default' : 'compact'}
				iconBefore={
					<IconTile
						icon={() => <ConfluenceIcon size="small" />}
						label={''} // The icon is described within the display name
						appearance={'blueBold'}
						size="24"
					/>
				}
				isSelected={selectedProduct === ProductKey.Confluence}
				onClick={() =>
					selectProductFilter(
						selectedProduct === ProductKey.Confluence ? undefined : ProductKey.Confluence,
					)
				}
				spacing={isDialogAbove400px ? 'default' : 'compact'}
			>
				{isDialogAbove400px && confluenceConfig.displayName}
			</FilterButton>

			{primaryProduct !== ProductKey.Atlas && !selectedProduct && apps3pComponent}

			{selectedProduct && <Box xcss={inlineFilterSeparator} />}
		</Box>
	);
};
