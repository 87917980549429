import React, { type ReactNode, useCallback } from 'react';

import { FormattedMessage } from 'react-intl-next';

import { fg } from '@atlaskit/platform-feature-flags';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { FocusAreaIcon } from '@atlassian/focus-area-icons';
import { isFocusAreaHierarchyLevel, isFocusAreaStatus } from '@atlassian/focus-area-icons/types';
import {
	isDocumentEntity,
	isSearchAtlasPartial,
	isSearchConfluencePageBlogAttachment,
	isSearchConfluencePartial,
	isSearchJiraPartial,
	isSearchMercuryPartial,
	isSearchResultGraphEntity,
	isSearchResultJiraIssue,
	isSearchResultMercuryFocusAreaPartial,
	isSearchResultMercuryFocusAreaStatusUpdatePartial,
	parseThirdPartyARI,
	type SearchAtlasPartial,
	type SearchConfluencePartial,
	SearchConfluenceSubtype,
	type SearchJiraPartial,
	type SearchMercuryPartial,
	type SearchPageNode,
	type SearchResultEntity,
	type SearchResultGraphDocument,
} from '@atlassian/search-client';
import {
	getConfluenceUrlWithAnalyticsQueryParams,
	getGenericUrlWithAnalyticsQueryParams,
	highlightMatchedText,
} from '@atlassian/search-common';

import { ReturnIcon } from '../../../../common/constants/icons/return';
import { ProductKeys } from '../../../../common/constants/products';
import { type ThirdPartyConfigsBootstrap } from '../../../../common/constants/schemas/3p-config';
import { EventTypeMessage, WORKED_EVENT } from '../../../../common/ui/event-type-message';
import { ProductIcon } from '../../../../common/ui/product-icon';
import { getDocumentSubtypeLabel } from '../../../../common/ui/quick-find/3p-result-labeling';
import { getStatus } from '../../../../common/ui/quick-find/jira-status';
import {
	SearchResult,
	type SearchResultEventArguments,
} from '../../../../common/ui/quick-find/search-dialog-result';
import { type SearchMetadataPart } from '../../../../common/ui/search-metadata';
import { type State } from '../../../../common/utils/quick-find/state';
import { getProductFromProviderId } from '../../../../common/utils/search-result-utils';
import { getSummarizedAdfContent } from '../../../../common/utils/summarized-adf-content';
import { type QuickFindResultEvent } from '../../../../controllers/quick-find/utils';
import {
	useBootstrap,
	useIsJiraHydrationLoading,
	useSearchSessionId,
} from '../../../../controllers/store';
import { useQuickFindQuery } from '../../../../controllers/store/quick-find';

import { messages } from './messages';

const typeLabelContainer = xcss({
	'::first-letter': {
		textTransform: 'capitalize',
	},
});

const mapConfluenceSearchResult = (
	result: SearchConfluencePartial,
	query: string,
	searchSessionId: string,
) => {
	const id = result.id;

	const icon = <ProductIcon size="small" product={ProductKeys.Confluence} />;

	const title = result.title;
	const highlightedTitle =
		title && query ? highlightMatchedText(title, query.split(' ')).highlightedTitle : undefined;

	const space =
		isSearchConfluencePageBlogAttachment(result) && result.space?.name ? result.space.name : null;
	const spaceId = isSearchConfluencePageBlogAttachment(result) && result.space?.id;

	const isLiveDoc =
		isSearchConfluencePageBlogAttachment(result) &&
		result.confluenceEntity?.subtype === SearchConfluenceSubtype.LIVE &&
		fg('confluence_live_pages_open_beta_trait_opted_in');

	const metadata = [
		'Confluence',
		[
			'type',
			isLiveDoc ? (
				<FormattedMessage {...messages.liveDoc} />
			) : (
				<Box xcss={typeLabelContainer}>{result.type}</Box>
			),
		],
		space,
	] satisfies SearchMetadataPart[];

	const timestamp = result.lastModified || '';
	const eventType = WORKED_EVENT.UPDATED;
	const rightMetadata = result.lastModified ? (
		<EventTypeMessage timestamp={timestamp} eventType={eventType} isMyActivityData={false} />
	) : null;
	const highlightedRightMetadata = (
		<Inline alignBlock="center" space="space.100">
			{rightMetadata}
			<ReturnIcon />
		</Inline>
	);

	const url = getConfluenceUrlWithAnalyticsQueryParams({ url: result.url, query, searchSessionId });

	const analyticsMetadata = {
		contentId: id,
		containerId: spaceId,
		contentType: `confluence ${result.type}`,
		product: `confluence`,
	};

	return {
		id,
		icon,
		title,
		highlightedTitle,
		metadata,
		rightMetadata,
		highlightedRightMetadata,
		url,
		analyticsMetadata,
	};
};

const mapAtlasSearchResult = (
	result: SearchAtlasPartial,
	query: string,
	searchSessionId: string,
) => {
	const id = result.id;
	const productIcon = <ProductIcon size="small" product={ProductKeys.Atlas} />;
	const icon = productIcon;

	const title = result.title;
	const highlightedTitle =
		title && query ? highlightMatchedText(title, query.split(' ')).highlightedTitle : undefined;

	const metadata = [
		'Atlas',
		['type', <Box xcss={typeLabelContainer}>{result.type}</Box>],
	] satisfies SearchMetadataPart[];

	const highlightedRightMetadata = (
		<Inline alignBlock="center" space="space.100">
			<ReturnIcon />
		</Inline>
	);

	const url = getGenericUrlWithAnalyticsQueryParams({ url: result.url, searchSessionId });

	const analyticsMetadata = {
		contentId: id,
		contentType: `atlas ${result.type}`,
		product: `atlas`,
	};

	return {
		id,
		icon,
		title,
		highlightedTitle,
		metadata,
		highlightedRightMetadata,
		url,
		analyticsMetadata,
	};
};

const mapMercurySearchResult = (
	result: SearchMercuryPartial,
	query: string,
	searchSessionId: string,
) => {
	const { id } = result;
	let icon = <ProductIcon size="small" product={ProductKeys.Focus} />;
	let resultType, title, highlightedTitle, metadata;

	if (isSearchResultMercuryFocusAreaPartial(result)) {
		const { focusArea } = result;
		const hierarchyLevel = focusArea?.focusAreaType.hierarchyLevel || 0;
		const currentStatus =
			(focusArea?.health ? focusArea.health.key : focusArea?.status.key) || 'pending';
		const level = isFocusAreaHierarchyLevel(hierarchyLevel) ? hierarchyLevel : 0;
		const status = isFocusAreaStatus(currentStatus) ? currentStatus : 'pending';

		icon = <FocusAreaIcon level={level} status={status} />;
		title = result.title;
		highlightedTitle =
			title && query ? highlightMatchedText(title, query.split(' ')).highlightedTitle : undefined;
		resultType = 'Focus area';
		metadata = [
			'Focus',
			['type', <Box xcss={typeLabelContainer}>{resultType}</Box>],
			result.focusArea ? ['focusAreaType', result.focusArea?.focusAreaType.name] : null,
		] satisfies SearchMetadataPart[];
	}

	if (isSearchResultMercuryFocusAreaStatusUpdatePartial(result)) {
		title = getSummarizedAdfContent(result.description, query);
		highlightedTitle =
			title && query ? highlightMatchedText(title, query.split(' ')).highlightedTitle : undefined;
		resultType = 'Focus area update';
		metadata = [
			'Focus',
			['type', <Box xcss={typeLabelContainer}>{resultType}</Box>],
		] satisfies SearchMetadataPart[];
	}

	const highlightedRightMetadata = (
		<Inline alignBlock="center" space="space.100">
			<ReturnIcon />
		</Inline>
	);

	const url = getGenericUrlWithAnalyticsQueryParams({ url: result.url, searchSessionId });

	const analyticsMetadata = {
		contentId: id,
		contentType: `mercury ${result.type}`,
		product: `mercury`,
	};

	return {
		id,
		icon,
		title,
		highlightedTitle,
		metadata,
		highlightedRightMetadata,
		url,
		analyticsMetadata,
	};
};

const mapThirdPartySearchResult = (
	result: SearchResultGraphDocument<SearchResultEntity>,
	query: string,
	searchSessionId: string,
	thirdPartyConfigs: ThirdPartyConfigsBootstrap,
) => {
	const id = result.id;
	let product = '';
	if (result.providerId) {
		product = getProductFromProviderId(thirdPartyConfigs, result.providerId) || 'graph';
	} else if (result.entity?.thirdPartyId) {
		const { resourceOwner } = parseThirdPartyARI(result.entity?.thirdPartyId);
		product =
			resourceOwner.split('.').at(1) === 'google-drive'
				? 'drive'
				: resourceOwner.split('.').at(1) || '';
	}

	const icon = <ProductIcon size="small" product={product as ProductKeys} />;

	const title = result.title;
	const highlightedTitle =
		title && query ? highlightMatchedText(title, query.split(' ')).highlightedTitle : undefined;

	const typeLabel = getDocumentSubtypeLabel({ product, subtype: result?.subtype });
	// TODO use providerId - https://product-fabric.atlassian.net/browse/AI3W-47

	const parentName = isDocumentEntity(result) ? result.entity?.parent?.displayName : null;

	const metadata = [
		[
			'type',
			typeLabel ? (
				<Box xcss={typeLabelContainer}>
					<FormattedMessage {...typeLabel} />
				</Box>
			) : result?.subtype ? (
				<Box xcss={typeLabelContainer}>
					{result?.subtype.charAt(0).toUpperCase() + result?.subtype.substring(1).toLowerCase()}
				</Box>
			) : null,
		],
		parentName ?? null,
	] satisfies SearchMetadataPart[];

	const timestamp = result.lastModifiedDate || '';
	const eventType = WORKED_EVENT.UPDATED;
	const rightMetadata = result.lastModifiedDate ? (
		<EventTypeMessage timestamp={timestamp} eventType={eventType} isMyActivityData={false} />
	) : null;
	const highlightedRightMetadata = (
		<Inline alignBlock="center" space="space.100">
			{rightMetadata}
			<ReturnIcon />
		</Inline>
	);

	const url = getGenericUrlWithAnalyticsQueryParams({ url: result.url, searchSessionId });

	const analyticsMetadata = {
		contentId: id,
		contentType: `${product} ${result.subtype}`,
		product: product,
	};

	return {
		id,
		icon,
		title,
		highlightedTitle,
		metadata,
		rightMetadata,
		highlightedRightMetadata,
		url,
		analyticsMetadata,
	};
};

const mapJiraSearchResult = (
	result: SearchJiraPartial,
	query: string,
	searchSessionId: string,
	isStatusLoading?: boolean,
) => {
	const id = result.id;
	const analyticsMetadata = {
		contentId: id,
		contentType: `jira ${result.type}`,
		product: `jira`,
	};
	const title = result.title;
	const icon = <ProductIcon size="small" product={ProductKeys.Jira} />;
	const highlightedTitle =
		title && query ? highlightMatchedText(title, query.split(' ')).highlightedTitle : undefined;

	const status = isSearchResultJiraIssue(result)
		? getStatus(result.status?.name, result.status?.colorName, isStatusLoading)
		: null;

	const titleMetadataParts: [string, ReactNode][] =
		status && fg('enable_status_jira_issues_quick_find') ? [['status', status]] : [];

	const metadata = [
		'Jira',
		['type', <Box xcss={typeLabelContainer}>{result.type}</Box>],
	] satisfies SearchMetadataPart[];

	const rightMetadata = isSearchResultJiraIssue(result) ? (
		<EventTypeMessage
			timestamp={result.lastModifiedDate || ''}
			eventType={WORKED_EVENT.UPDATED}
			isMyActivityData={false}
		/>
	) : null;

	const highlightedRightMetadata = (
		<Inline alignBlock="center" space="space.100">
			<ReturnIcon />
		</Inline>
	);
	const url = getGenericUrlWithAnalyticsQueryParams({ url: result.url, searchSessionId });
	return {
		id,
		title,
		highlightedTitle,
		icon,
		status,
		analyticsMetadata,
		metadata,
		highlightedRightMetadata,
		rightMetadata,
		url,
		titleMetadataParts,
	};
};

const mapSearchResult = (
	result: SearchPageNode,
	query: string,
	searchSessionId: string,
	thirdPartyConfigs: ThirdPartyConfigsBootstrap,
	isStatusLoading?: boolean,
) => {
	if (isSearchConfluencePartial(result)) {
		return mapConfluenceSearchResult(result, query, searchSessionId);
	} else if (isSearchAtlasPartial(result)) {
		return mapAtlasSearchResult(result, query, searchSessionId);
	} else if (isSearchMercuryPartial(result)) {
		return mapMercurySearchResult(result, query, searchSessionId);
	} else if (isSearchResultGraphEntity(result)) {
		return mapThirdPartySearchResult(result, query, searchSessionId, thirdPartyConfigs);
	} else if (isSearchJiraPartial(result)) {
		return mapJiraSearchResult(result, query, searchSessionId, isStatusLoading);
	} else {
		return null;
	}
};

type Props = {
	result: SearchPageNode;
	state: State;
	onClick?: (props: QuickFindResultEvent) => void;
	onContextMenu?: (props: QuickFindResultEvent) => void;
	onHighlighted?: (props: QuickFindResultEvent) => void;
};

export const ProductEntity = ({ result, state, onClick, onContextMenu, onHighlighted }: Props) => {
	const quickFindQuery = useQuickFindQuery();
	const [searchSessionId] = useSearchSessionId();
	const [{ thirdPartyConfigs }] = useBootstrap();
	const isHydrationLoading = useIsJiraHydrationLoading();

	const mappedResult = mapSearchResult(
		result,
		quickFindQuery,
		searchSessionId,
		thirdPartyConfigs,
		isHydrationLoading,
	);

	const onResultClicked = useCallback(
		(event: React.MouseEvent<HTMLDivElement>, data: SearchResultEventArguments) => {
			onClick?.({
				event,
				data,
				state,
				additionalAnalyticsAttributes: { metadata: mappedResult?.analyticsMetadata },
			});
		},
		[mappedResult?.analyticsMetadata, onClick, state],
	);

	const onResultContextMenu = useCallback(
		(event: React.MouseEvent<HTMLDivElement>, data: SearchResultEventArguments) => {
			onContextMenu?.({
				event,
				data,
				state,
				additionalAnalyticsAttributes: { metadata: mappedResult?.analyticsMetadata },
			});
		},
		[mappedResult?.analyticsMetadata, onContextMenu, state],
	);

	const onResultHighlighted = useCallback(
		(data: SearchResultEventArguments) => {
			onHighlighted?.({
				data,
				state,
				additionalAnalyticsAttributes: { metadata: mappedResult?.analyticsMetadata },
			});
		},
		[mappedResult?.analyticsMetadata, onHighlighted, state],
	);

	if (!mappedResult) {
		return null;
	}

	return (
		<SearchResult
			{...mappedResult}
			resultType="searchResults"
			product={mappedResult.analyticsMetadata.product}
			onResultClicked={onResultClicked}
			onResultContextMenu={onResultContextMenu}
			onResultHighlighted={onResultHighlighted}
		/>
	);
};
