import React, { useCallback } from 'react';

import { useIntl } from 'react-intl-next';

import SearchIcon from '@atlaskit/icon/core/search';
import { fg } from '@atlaskit/platform-feature-flags';
import { Inline } from '@atlaskit/primitives';

import { ReturnIcon } from '../../../../common/constants/icons/return';
import { useAppContext } from '../../../../common/ui/app-context';
import { SearchFooter } from '../../../../common/ui/quick-find/search-dialog-footer';
import { preloadSearchPageResults } from '../../../../common/utils/preload-results';
import { type State } from '../../../../common/utils/quick-find/state';
import { type QuickFindFooterEvent } from '../../../../controllers/quick-find/utils';
import { usePrimaryProduct, useSearchActions } from '../../../../controllers/store';
import { useQuickFindQuery } from '../../../../controllers/store/quick-find';

import { messages } from './messages';

type Props = {
	state: State;
	onClick: (props: QuickFindFooterEvent) => void;
};

export const SearchPageLink = ({ state, onClick }: Props) => {
	const intl = useIntl();

	const { generateSearchUrl, queryParams } = useAppContext();

	const [primaryProduct] = usePrimaryProduct();
	const quickFindQuery = useQuickFindQuery();
	const { clearAllFilters } = useSearchActions();

	const onResultClicked = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			onClick({
				state,
				event,
				actionSubjectId: `${primaryProduct?.toLowerCase() || ''}AdvancedSearchLink`,
			});
			if (fg('rovo_search_quick_find_prefetch')) {
				preloadSearchPageResults({ searchQuery: quickFindQuery });
			}

			if (fg('quick_find_filters')) {
				// TODO: This is a temporary measure until we do QS-6716 post Team 25 where it syncs filters with FPS.
				// For now, executing a search query on FPS search will always clear the filters in QF as FPS filters
				// is the source of truth for filter state.
				clearAllFilters();
			}
		},
		[onClick, state, primaryProduct, quickFindQuery, clearAllFilters],
	);

	const icon = (
		<SearchIcon label={intl.formatMessage(messages.searchAcrossProducts)} color="currentColor" />
	);

	const title = intl.formatMessage(messages.searchAcrossProducts);

	const rightMetadata = (
		<Inline alignBlock="center" space="space.100">
			<ReturnIcon />
		</Inline>
	);

	const url = generateSearchUrl({
		...queryParams,
		text: quickFindQuery,
	});

	return (
		<SearchFooter
			icon={icon}
			title={title}
			rightMetadata={rightMetadata}
			url={url}
			onResultClicked={onResultClicked}
		/>
	);
};
