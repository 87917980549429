import React, { type PropsWithChildren, useEffect, useRef, useState } from 'react';

// eslint-disable-next-line @atlaskit/design-system/no-banned-imports
import { useId } from '@atlaskit/ds-lib/use-id';
import { FlagsProvider } from '@atlaskit/flag';
import { fg } from '@atlaskit/platform-feature-flags';
import { Box, xcss } from '@atlaskit/primitives';
import UFOSegment from '@atlaskit/react-ufo/segment';
import { KeyboardHighlightProvider } from '@atlassian/search-dialog';
import { STATSIG_QUICK_FIND_LAYER } from '@atlassian/search-experiment';

import type { QueryParams } from '../../common/types';
import { AppContextProvider, type AppContextProviderProps } from '../../common/ui/app-context';
import type { TextfieldProps } from '../../common/ui/quick-find/textfield';
import { getInitialQuery } from '../../common/ui/quick-find/textfield/input-skeleton';
import { WidthObserverProvider } from '../../common/ui/width-observer';
import { defaultGenerateSearchUrl } from '../../common/utils/url';
import { QuickFindResults } from '../../controllers/quick-find/fetch-results';
import {
	type BootstrapContainerProps,
	BootstrapStoreProvider,
} from '../../controllers/store/bootstrap/bootstrap-store-provider';
import { useQuickFindActions, useQuickFindOpen } from '../../controllers/store/quick-find';
import { QuickFindStoreProvider } from '../../controllers/store/quick-find/quick-find-store-provider';
import { SearchStoreProvider } from '../../controllers/store/search/search-store-provider';

import { SearchDialog } from './dialog';
import { LazyPreload } from './lazy-preload';
import { SearchTextField } from './textfield';
import { useCloseManager, usePreQueryDwellTimeAnalytics, useSyncStates } from './utils';

const textFieldBoxStyles = xcss({
	width: '100%',
	position: 'relative',
});

export type QuickFindComponentProps = {
	open?: boolean;
	setOpen?: (open: boolean) => void;
	postQueryDisabled?: boolean;
	textfieldComponent?: React.ComponentType<TextfieldProps>;
};

type QuickFindProps = BootstrapContainerProps & AppContextProviderProps & QuickFindComponentProps;

const QuickFindComponent = ({
	open,
	setOpen,
	postQueryDisabled,
	textfieldComponent,
}: QuickFindComponentProps) => {
	const inputBoxRef = useRef<HTMLDivElement>(null);
	const dialogRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	const [localQuery, setLocalQuery] = useState(getInitialQuery);

	const { setQuickFindOpen } = useQuickFindActions();
	const quickFindOpen = useQuickFindOpen();

	const dialogId = useId();

	useSyncStates(quickFindOpen, setQuickFindOpen, open, setOpen);

	useCloseManager({ dialogRef, inputBoxRef });
	usePreQueryDwellTimeAnalytics();

	return (
		<>
			<QuickFindResults />
			<Box xcss={textFieldBoxStyles} ref={inputBoxRef}>
				<KeyboardHighlightProvider listenerNode={inputRef?.current}>
					{fg('rovo_search_quick_find_prefetch') && <LazyPreload />}
					<UFOSegment name="quick-find-search-textfield">
						<SearchTextField
							dialogId={dialogId}
							inputRef={inputRef}
							localQuery={localQuery}
							setLocalQuery={setLocalQuery}
							textfieldComponent={textfieldComponent}
							postQueryDisabled={postQueryDisabled}
						/>
					</UFOSegment>
					<FlagsProvider>
						{quickFindOpen && (
							<UFOSegment name="quick-find-search-dialog">
								<WidthObserverProvider>
									<SearchDialog
										dialogId={dialogId}
										dialogRef={dialogRef}
										inputRef={inputRef}
										localQuery={localQuery}
										setLocalQuery={setLocalQuery}
									/>
								</WidthObserverProvider>
							</UFOSegment>
						)}
					</FlagsProvider>
				</KeyboardHighlightProvider>
			</Box>
		</>
	);
};

const QuickFindShell = ({
	children,
	queryParams: actualQueryParams,
	...props
}: PropsWithChildren<QuickFindProps>) => {
	/**
	 * Quick Find does not need to set queryParams, hence using an object
	 * in a state variable as the queryParams object. Any interactions with
	 * the url should be done through onNavigate in the AppContext.
	 */
	const [queryParams, setQueryParams] = useState<QueryParams>({ text: actualQueryParams?.text });

	/**
	 * Quick Find only reacts to changes in the text query parameter when the
	 * user is on the search page.
	 */
	const previousTextQueryParam = useRef<string | undefined>(actualQueryParams?.text);
	useEffect(() => {
		if (actualQueryParams?.text === previousTextQueryParam.current) {
			return;
		}

		let onSearchRoute = true; // Default to true in case URL parsing fails

		try {
			const generateSearchUrl = props.generateSearchUrl || defaultGenerateSearchUrl;
			const searchUrl = new URL(generateSearchUrl({}));
			const searchPath = searchUrl.pathname;

			const currentUrl = new URL(window.location.href);
			const currentPath = currentUrl.pathname;

			onSearchRoute = searchPath === currentPath;
		} catch (e) {
			// ignore error
		}

		if (onSearchRoute) {
			setQueryParams((previousQueryParams) => ({
				...previousQueryParams,
				text: actualQueryParams?.text,
			}));
		}

		previousTextQueryParam.current = actualQueryParams?.text;
	}, [actualQueryParams?.text, props.generateSearchUrl]);

	return (
		<BootstrapStoreProvider
			{...props}
			queryParams={queryParams}
			callbacks={{ updateQueryParameters: setQueryParams }}
			statsigLayer={STATSIG_QUICK_FIND_LAYER}
		>
			<SearchStoreProvider>
				<QuickFindStoreProvider>
					<AppContextProvider {...props} queryParams={queryParams}>
						{children}
					</AppContextProvider>
				</QuickFindStoreProvider>
			</SearchStoreProvider>
		</BootstrapStoreProvider>
	);
};

export const QuickFind = ({ children, ...props }: PropsWithChildren<QuickFindProps>) => {
	return (
		<UFOSegment name="quick-find">
			<QuickFindShell {...props}>
				<QuickFindComponent {...props} />
			</QuickFindShell>
		</UFOSegment>
	);
};
