import React, { type RefObject, useCallback, useEffect, useRef } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import { addRecentSearch, RECENT_SEARCH_KEY } from '@atlassian/search-common';
import { useKeyboardHighlightProvider } from '@atlassian/search-dialog';

import { ApplicationModes } from '../../../common/constants/application-modes';
import { PrimaryProductKeys } from '../../../common/constants/products';
import { useAppContext } from '../../../common/ui/app-context';
import {
	Textfield as DefaultTextfield,
	type TextfieldProps,
} from '../../../common/ui/quick-find/textfield';
import { useInputSkeleton } from '../../../common/ui/quick-find/textfield/input-skeleton';
import { preloadSearchPageResults } from '../../../common/utils/preload-results';
import { onAdvancedSearchLinkSelected } from '../../../common/utils/quick-find/events/advanced-search-link-selected';
import { State } from '../../../common/utils/quick-find/state';
import {
	useQuickFindAnalytics,
	useQuickFindAttributes,
} from '../../../controllers/quick-find/analytics';
import { usePlaceholderText } from '../../../controllers/quick-find/input-placeholder';
import { getQueryWithoutSelectedQueryFilters } from '../../../controllers/quick-find/query-filters/utils';
import {
	useApplicationMode,
	useBootstrap,
	useSearchActions,
	useSelectedProduct,
} from '../../../controllers/store';
import { useAvailableProducts, useIsConfigLoaded } from '../../../controllers/store/bootstrap';
import {
	useContent,
	useQuickFindActions,
	useQuickFindOpen,
	useQuickFindQuery,
	useSelectedQueryFilters,
	useUpdateRecentQueries,
} from '../../../controllers/store/quick-find';

import { useQueryFilters } from './query-filters';
import { useDebouncedOnTextEnteredAnalyticsCallback } from './utils';

export type SearchTextFieldProps = {
	dialogId: string;
	inputRef?: RefObject<HTMLInputElement>;
	localQuery: string;
	setLocalQuery: React.Dispatch<React.SetStateAction<string>>;
	textfieldComponent?: React.ComponentType<TextfieldProps>;
	postQueryDisabled?: boolean;
};

export const SearchTextField = ({
	dialogId,
	inputRef,
	localQuery,
	setLocalQuery,
	textfieldComponent,
	postQueryDisabled,
}: SearchTextFieldProps) => {
	const previousTextQueryParam = useRef<string | boolean | string[] | undefined>();

	const { generateSearchUrl, onNavigate, queryParams, isNav4Enabled, isAdminHubAIEnabled } =
		useAppContext();

	const applicationMode = useApplicationMode();
	const isConfigLoaded = useIsConfigLoaded();
	const [{ primaryProduct, user }] = useBootstrap();
	const content = useContent();
	const quickFindOpen = useQuickFindOpen();
	const quickFindQuery = useQuickFindQuery();
	const { getSelectedIndex } = useKeyboardHighlightProvider();
	const updateRecentQueries = useUpdateRecentQueries();
	const selectedQueryFilters = useSelectedQueryFilters();
	const [selectedProduct] = useSelectedProduct();

	const { setQuickFindOpen, resetSelectedQueryFilters } = useQuickFindActions();
	const { setQueryUpdatedTime, clearAllFilters, setInputQuery } = useSearchActions();

	const { fireAnalyticsEvent } = useQuickFindAnalytics();
	const { commonAttributes, nonPrivacySafeAttributes } = useQuickFindAttributes();

	const availableProducts = useAvailableProducts();

	const debouncedOnTextEnteredAnalyticsCallback = useDebouncedOnTextEnteredAnalyticsCallback();

	const { placeholder, incrementSession } = usePlaceholderText({
		applicationMode,
		isAdminHubAIEnabled,
		isNav4Enabled,
		quickFindOpen,
		availableProducts,
	});

	useInputSkeleton({
		localQuery,
		inputRef,
	});

	useQueryFilters(localQuery, postQueryDisabled);

	// Only when the text query param changes, update the local query. If the
	// local query without selected query filters is the same as text query param,
	// do nothing.
	useEffect(() => {
		if (queryParams.text === previousTextQueryParam.current) {
			return;
		}

		previousTextQueryParam.current = queryParams.text;

		const localQueryWithoutSelectedQueryFilters = getQueryWithoutSelectedQueryFilters(
			localQuery,
			selectedQueryFilters,
		);

		const newLocalQuery = typeof queryParams.text === 'string' ? queryParams.text : '';

		if (localQueryWithoutSelectedQueryFilters !== newLocalQuery) {
			setInputQuery(newLocalQuery);
			setLocalQuery(newLocalQuery);
			clearAllFilters();
			resetSelectedQueryFilters();
		}
	}, [
		clearAllFilters,
		queryParams.text,
		resetSelectedQueryFilters,
		selectedQueryFilters,
		setLocalQuery,
		localQuery,
		setInputQuery,
	]);

	// Update query time when query changes
	useEffect(() => {
		setQueryUpdatedTime();
	}, [setQueryUpdatedTime, localQuery]);

	useEffect(() => {
		if (quickFindOpen) {
			inputRef?.current?.focus();
		} else {
			inputRef?.current?.blur();
		}
	}, [inputRef, quickFindOpen]);

	// Increments the session so we can display different ghost text next time
	useEffect(() => {
		incrementSession();
		// Only increment session on page load
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			/**
			 * Ignore enter key press if a search result is highlighted as we let
			 * the keyboard highlight provider handle the event in this case.
			 */
			const resultHighlighted = getSelectedIndex && getSelectedIndex() !== undefined;

			if (event.key === 'Enter' && !resultHighlighted) {
				if (!primaryProduct) {
					return;
				}

				/**
				 * Stop the event from propagating to prevent UFO
				 * VC90 metric from being cancelled on route change.
				 */
				event.stopPropagation();

				// Prefetch FPS results
				if (fg('rovo_search_quick_find_prefetch')) {
					preloadSearchPageResults({ searchQuery: quickFindQuery });
				}

				// Store search in recent searches
				if (quickFindQuery.trim() !== '' && user && user.id && primaryProduct) {
					addRecentSearch(quickFindQuery.trim(), user.id, primaryProduct, RECENT_SEARCH_KEY);
					updateRecentQueries?.();
				}

				event.currentTarget.blur();
				setQuickFindOpen(false);

				fireAnalyticsEvent(
					onAdvancedSearchLinkSelected({
						actionSubjectId: `${primaryProduct.toLowerCase()}AdvancedSearchLink`,
						attributes: {
							...commonAttributes,
							trigger: 'return',
							newTab: false,
							isLoading:
								content?.state === State.PREQUERY_LOADING ||
								content?.state === State.POSTQUERY_LOADING,
						},
						nonPrivacySafeAttributes,
					}),
				);

				const searchForPrimaryProduct =
					!selectedProduct &&
					((applicationMode === ApplicationModes.Rovo && (event.metaKey || event.ctrlKey)) ||
						(applicationMode !== ApplicationModes.Rovo && !(event.metaKey || event.ctrlKey)));

				if (fg('quick_find_filters')) {
					// TODO: This is a temporary measure until we do QS-6716 post Team 25 where it syncs filters with FPS.
					// For now, executing a search query on FPS search will always clear the filters in QF as FPS filters
					// is the source of truth for filter state.
					clearAllFilters();
				}

				const onNavigateCallback =
					typeof onNavigate === 'function' ? onNavigate : onNavigate.callback;

				onNavigateCallback(
					generateSearchUrl(
						{
							...queryParams,
							product: searchForPrimaryProduct ? primaryProduct : undefined,
							text: quickFindQuery,
						},
						!!(primaryProduct === PrimaryProductKeys.Jira && (event.metaKey || event.ctrlKey)),
					),
					'push',
				);
			}

			if (event.key === 'Escape') {
				event.currentTarget.blur();
				setQuickFindOpen(false);
			}
		},
		[
			getSelectedIndex,
			primaryProduct,
			quickFindQuery,
			user,
			setQuickFindOpen,
			fireAnalyticsEvent,
			commonAttributes,
			content?.state,
			nonPrivacySafeAttributes,
			selectedProduct,
			applicationMode,
			clearAllFilters,
			onNavigate,
			generateSearchUrl,
			queryParams,
			updateRecentQueries,
		],
	);

	const onChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const newQuery = event.currentTarget.value;
			setLocalQuery(newQuery);
			debouncedOnTextEnteredAnalyticsCallback();
		},
		[debouncedOnTextEnteredAnalyticsCallback, setLocalQuery],
	);

	const Textfield = textfieldComponent || DefaultTextfield;

	return (
		<Textfield
			value={localQuery}
			dialogId={dialogId}
			inputRef={inputRef}
			appMode={applicationMode}
			quickFindOpen={quickFindOpen}
			isNav4Enabled={isNav4Enabled}
			isAdminHubAIEnabled={isAdminHubAIEnabled}
			onFocus={() => setQuickFindOpen(true)}
			onKeyDown={onKeyDown}
			onChange={onChange}
			onClear={() => {
				setLocalQuery('');
				inputRef?.current?.focus();
			}}
			placeholder={isConfigLoaded ? placeholder : undefined}
		/>
	);
};
